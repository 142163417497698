import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { toursApiPlugin } from '../api';
import { ToursService } from './tours-service';
import { storagePlugin } from '@/features/core/storage';
import { productsServicePlugin } from '@/features/products';
import { ToursOrderAdapterService } from './tours-order-adapter-service';
import { Tour } from '../types';
import { OrderAdapterService } from '@/features/orders';
import { loggerServicePlugin } from '@/features/core/logger';
import { TourDeliveryUnitSortingService } from './tours-delivery-unit-sorting-service';
import { TourStatusToActionService } from './tour-status-to-action-service';
import { DriverAppInitService } from './driver-app-init-service';
import { driverNotificationsRegistryPlugin } from '@/features/driver-notifications';
import { ToursNotificationProcessor } from './tours-notification-processor';

export * from './tours-service';
export * from './tours-notification-processor';
export * from './tours-order-adapter-service';

export const toursOrderAdapterServicePlugin = ProviderPluginFactory.create<
  OrderAdapterService<Tour>
>({
  key: Symbol('ToursOrderAdapterService'),
  defaultFactory: {
    create: () => {
      return new ToursOrderAdapterService(
        storagePlugin.get(),
        loggerServicePlugin.get(),
      );
    },
  },
});

export const toursServicePlugin = ProviderPluginFactory.create<ToursService>({
  key: Symbol('ToursService'),
  defaultFactory: {
    create: () => {
      return new ToursService(
        toursApiPlugin.get(),
        storagePlugin.get(),
        productsServicePlugin.get(),
        toursOrderAdapterServicePlugin.get(),
        loggerServicePlugin.get(),
      );
    },
  },
});

export const toursNotificationProcessorPlugin = ExecPluginFactory.fn(() => {
  driverNotificationsRegistryPlugin
    .get()
    .register(
      'TourNotificationProcessor',
      new ToursNotificationProcessor(storagePlugin.get()),
    );
});

export const toursDeliveryUnitSortingServicePlugin =
  ProviderPluginFactory.create<TourDeliveryUnitSortingService>({
    key: Symbol('toursDeliveryUnitSortingService'),
    defaultFactory: {
      create: () => {
        return new TourDeliveryUnitSortingService();
      },
    },
  });

export const tourStatusToActionServicePlugin =
  ProviderPluginFactory.create<TourStatusToActionService>({
    key: Symbol('TourStatusToActionMapService'),
    defaultFactory: {
      create: () => {
        return new TourStatusToActionService();
      },
    },
  });

export const driverAppInitServicePlugin =
  ProviderPluginFactory.create<DriverAppInitService>({
    key: Symbol('DriverAppInitService'),
    defaultFactory: {
      create: () => {
        return new DriverAppInitService(
          toursServicePlugin.get(),
          router.get(),
          errorPlugin.get(),
          loggerServicePlugin.get(),
        );
      },
    },
  });
