<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9998 20.5C7.3055 20.5 3.49998 16.6944 3.49998 12C3.49998 7.30558 7.3055 3.5 11.9998 3.5C16.6942 3.5 20.4997 7.30558 20.4997 12C20.4997 13.1738 20.2622 14.2903 19.8333 15.3053C19.7826 15.4253 19.7292 15.544 19.6733 15.6611C19.3755 16.2839 19.5171 17.058 20.0895 17.4439L20.2968 17.5836C20.4248 17.3938 20.5464 17.1994 20.6614 17.0005C20.7665 16.8189 20.8661 16.6336 20.9598 16.4449C21.0504 16.2627 21.1355 16.0774 21.2151 15.8891C21.7206 14.6926 21.9997 13.378 21.9997 12C21.9997 6.47715 17.5226 2 11.9998 2C6.47708 2 2 6.47715 2 12C2 17.5228 6.47708 22 11.9998 22C13.3778 22 14.6925 21.7209 15.8889 21.2153C16.0772 21.1358 16.2625 21.0507 16.4447 20.9601C16.6334 20.8664 16.8187 20.7668 17.0003 20.6617C17.1895 20.5523 17.3748 20.4368 17.5558 20.3156L17.4168 20.1079C17.0328 19.5342 16.2592 19.39 15.6354 19.6856C15.5266 19.7372 15.4164 19.7866 15.3051 19.8336C14.2901 20.2625 13.1737 20.5 11.9998 20.5Z"
    />
    <path
      d="M11.9998 5.75C12.4141 5.75 12.7498 6.08579 12.7498 6.5V11.6893L15.5301 14.4697C15.823 14.7626 15.823 15.2374 15.5301 15.5303C15.2372 15.8232 14.7624 15.8232 14.4695 15.5303L11.4695 12.5303C11.3976 12.4584 11.3434 12.3755 11.3068 12.2871C11.2701 12.1987 11.2499 12.1017 11.2499 12V6.5C11.2499 6.08579 11.5856 5.75 11.9998 5.75Z"
    />
  </svg>
</template>
