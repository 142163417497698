import { DeliveryUnit } from '@/features/tour-drops';
import {
  CleveronTemperatureClassListOrder,
  HomeDeliveryTemperatureClassListOrder,
} from '@/features/products';
import { DeliveryType, DeliveryUnitType } from '../types';
import { Receptacle } from '@/features/tour-picking-receptacles';

export class TourDeliveryUnitSortingService {
  sortDeliveryUnits(
    deliveryUnits: DeliveryUnit[],
    tourType = DeliveryType.HOME_DELIVERY,
  ): DeliveryUnit[] {
    const type: DeliveryUnitType = deliveryUnits[0].type;
    const legacySortingNeeded = deliveryUnits.some(
      (deliveryUnit: DeliveryUnit) => !deliveryUnit.sortingOrder,
    );
    if (legacySortingNeeded && type === DeliveryUnitType.frame) {
      return deliveryUnits.sort((a, b) =>
        this.legacyFrameSortingFunction(a, b, tourType),
      );
    } else if (legacySortingNeeded && type === DeliveryUnitType.box) {
      return deliveryUnits.sort((a, b) =>
        this.sortBySortingOrderFunction(a, b),
      );
    }
    return deliveryUnits.sort((a, b) => this.sortBySortingOrderFunction(a, b));
  }

  sortReceptacles(receptacles: Receptacle[]): Receptacle[] {
    const legacySortingNeeded = !receptacles.some(
      (receptacle: Receptacle) => receptacle.sortingOrder,
    );

    return receptacles.sort(
      legacySortingNeeded
        ? (a, b) => this.legacyBoxSortingFunction(a, b)
        : (a, b) => this.sortBySortingOrderFunction(a, b),
    );
  }

  private legacyFrameSortingFunction(
    a: DeliveryUnit,
    b: DeliveryUnit,
    deliveryType: DeliveryType,
  ): number {
    return (
      getTemperatureClassOrderRank(a, deliveryType) -
      getTemperatureClassOrderRank(b, deliveryType)
    );
  }

  private legacyBoxSortingFunction(
    a: { label: { position: string }[] },
    b: { label: { position: string }[] },
  ): number {
    return a.label[0].position.localeCompare(b.label[0].position, undefined, {
      numeric: true,
    });
  }

  private sortBySortingOrderFunction(
    a: { sortingOrder?: number },
    b: { sortingOrder?: number },
  ): number {
    if (!a.sortingOrder || !b.sortingOrder) return 0;
    return a.sortingOrder - b.sortingOrder;
  }
}

const getTemperatureClassList = (deliveryMode: DeliveryType) => {
  if (deliveryMode === DeliveryType.CLEVERON) {
    return CleveronTemperatureClassListOrder;
  }
  return HomeDeliveryTemperatureClassListOrder;
};

const getTemperatureClassOrderRank = (
  { temperatureClasses }: DeliveryUnit,
  deliveryMode: DeliveryType,
): number => {
  const temperatureClassListOrder = getTemperatureClassList(deliveryMode);
  return Math.max(
    ...temperatureClasses.map(
      (tempClass) => temperatureClassListOrder[tempClass],
    ),
  );
};
