import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory, Router } from 'vue-router';
import { oauthServicePlugin } from '@/features/oauth';
import { $t } from '@/i18n';
import { MultiPluginFactory, ProviderPluginFactory } from '../plugin';
import { loggerServicePlugin } from '../logger/plugin';
import { shouldLogRouteChange } from '@/utils/helpers/shouldLogRouteChange';
import { DRIVER_APP_CONTEXT } from './constants';

export let lastPosition = { left: 0, top: 0 };
export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      {
        path: '/',
        name: 'default',
        meta: {
          showCustomerCheckinBanner: true,
        },
        component: () => import('@/features/orders/views/OrdersList.vue'),
      },
      {
        path: '/online-product-search',
        name: 'product-search',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () =>
          import('@/features/product-search/views/ProductSearch.vue'),
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          layout: 'Login',
          skipRouteLogs: true,
        },
        component: () => import('@/features/login/views/Login.vue'),
      },
      {
        path: 'start',
        name: 'start',
        meta: {
          preventRedirectFromOrdersList: true,
          skipRouteLogs: true,
        },
        component: () => import('@/features/start/views/StartPage.vue'),
      },
      {
        path: '/',
        name: 'verification',
        component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
        children: [
          {
            path: '/age-verification/:id',
            name: 'age-verification',
            props: true,
            meta: {
              disabledLogout: true,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            component: () =>
              import('@/features/age-verification/views/AgeVerification.vue'),
          },
        ],
      },
      {
        path: '/print/:id',
        name: 'label-print',
        children: [
          {
            path: 'zebra',
            name: 'label-print-zebra',
            props: true,
            meta: {
              showSameDayNotification: true,
            },
            component: () =>
              import('@/features/label-print/view/ZebraLabelPrinter.vue'),
          },
          {
            path: 'zebra-qr',
            name: 'label-print-zebra-qr',
            props: true,
            meta: {
              showSameDayNotification: true,
            },
            component: () =>
              import('@/features/label-print/view/ZebraLabelQRCodePrinter.vue'),
          },
          {
            path: '',
            name: 'label-print-default',
            props: true,
            meta: {
              showSameDayNotification: true,
            },
            component: () =>
              import('@/features/label-print/view/LabelPrinter.vue'),
          },
        ],
        component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
      },
    ],
    name: 'home',
    component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
  },
  {
    path: '/tours/:tourId?',
    component: () => import('@/features/tours/views/ToursRoot.vue'),
    meta: {
      appCrashFeature: {
        trackRoute: true,
      },
      context: DRIVER_APP_CONTEXT,
    },
    children: [
      {
        path: '',
        name: 'tours',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
          processRouteGuard: {
            canLeaveRoute: false,
          },
        },
        component: () => import('@/features/tours/views/Tours.vue'),
      },
      {
        name: 'tour-loading',
        path: 'loading',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () =>
          import('@/features/tour-loading/views/TourLoading.vue'),
      },
      {
        name: 'tour-hotspot',
        path: 'hotspot',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () => import('@/features/tours/views/HotspotHint.vue'),
      },
      {
        path: 'drops',
        name: 'tour-drops',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () => import('@/features/tour-drops/views/Drops.vue'),
      },
      {
        path: 'tour-finish-checklist',
        name: 'tour-finish-checklist',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () =>
          import('@/features/tour-finish/views/MilkrunTourFinishChecklist.vue'),
      },
      {
        path: 'cleveron-finish-checklist',
        name: 'cleveron-finish-checklist',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () =>
          import(
            '@/features/tour-finish/views/CleveronTourFinishChecklist.vue'
          ),
      },
      {
        path: 'tour-finish-complete',
        name: 'tour-finish-complete',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () =>
          import('@/features/tour-finish/views/MilkrunTourFinishComplete.vue'),
      },
      {
        path: 'cleveron-finish-complete',
        name: 'cleveron-finish-complete',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () =>
          import('@/features/tour-finish/views/CleveronTourFinishComplete.vue'),
      },
      {
        path: 'drops/:dropId?',
        name: 'tour-drop',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () => import('@/features/tour-drops/views/DropsRoot.vue'),
        children: [
          {
            path: '',
            name: 'tour-drops',
            meta: {
              appCrashFeature: {
                trackRoute: true,
              },
              processRouteGuard: {
                canLeaveRoute: true,
                redirectTo: 'tours',
              },
            },
            component: () => import('@/features/tour-drops/views/Drops.vue'),
          },
          {
            path: 'receptacles',
            name: 'picking-receptacles',
            meta: {
              appCrashFeature: {
                trackRoute: true,
              },
            },
            component: () =>
              import('@/features/tour-drops/views/PickingReceptacles.vue'),
          },
          {
            name: 'cleaning-cleveron',
            path: 'cleaning-cleveron',
            component: () =>
              import('@/features/cleveron-cleaning/views/CleveronCleaning.vue'),
            meta: {
              processRouteGuard: {
                canLeaveRoute: true,
              },
            },
          },
          {
            name: 'box-clearing-cleveron',
            path: 'box-clearing-cleveron',
            component: () =>
              import(
                '@/features/cleveron-box-clearing/views/CleveronBoxClearing.vue'
              ),
            meta: {
              processRouteGuard: {
                canLeaveRoute: true,
              },
            },
          },
          {
            name: 'loading-cleveron',
            path: 'loading-cleveron',
            component: () =>
              import('@/features/cleveron-loading/views/CleveronLoading.vue'),
            meta: {
              processRouteGuard: {
                canLeaveRoute: true,
                redirectTo: 'tour-drops',
                redirectParams: ['tourId'],
              },
            },
          },
          {
            name: 'logout-cleveron',
            path: 'logout-cleveron',
            component: () =>
              import('@/features/cleveron-logout/views/CleveronLogout.vue'),
            meta: {
              processRouteGuard: {
                canLeaveRoute: true,
              },
            },
          },
          {
            name: 'unloading-middlemile',
            path: 'unloading',
            component: () =>
              import(
                '@/features/tour-drops/views/middlemile/CrossdockUnloading.vue'
              ),
          },
          {
            name: 'loading-middlemile',
            path: 'loading',
            component: () =>
              import(
                '@/features/tour-drops/views/middlemile/CrossdockLoadingEmptyFrames.vue'
              ),
            meta: {
              processRouteGuard: {
                canLeaveRoute: true,
                redirectTo: 'tour-drops',
                redirectParams: ['tourId'],
              },
            },
          },
          {
            path: 'handover/:id',
            children: [
              {
                path: 'overview',
                name: 'handover-drops',
                meta: {
                  activatedScreenWakeLock: false,
                  disabledLogout: true,
                  appCrashFeature: {
                    trackRoute: true,
                  },
                },
                props: true,
                component: () =>
                  import('@/features/tour-drops/views/HandoverOrder.vue'),
              },
              {
                path: 'age-verification',
                name: 'age-verification-tours',
                props: true,
                meta: {
                  disabledLogout: true,
                  appCrashFeature: {
                    trackRoute: true,
                  },
                },
                component: () =>
                  import(
                    '@/features/age-verification/views/AgeVerification.vue'
                  ),
              },
              {
                path: 'complete',
                name: 'handover-drops-complete',
                meta: {
                  layout: 'Picking',
                  activatedScreenWakeLock: false,
                  disabledLogout: true,
                  appCrashFeature: {
                    trackRoute: true,
                  },
                },
                props: true,
                component: () =>
                  import('@/features/handover/views/HandoverComplete.vue'),
              },
              {
                path: 'bottle-deposit',
                name: 'bottle-deposit-handover',
                props: true,
                component: () =>
                  import('@/features/handover/views/HandoverBottleDeposit.vue'),
              },
            ],
          },
          {
            path: 'survey',
            name: 'survey',
            meta: {
              appCrashFeature: {
                trackRoute: true,
              },
              processRouteGuard: {
                canLeaveRoute: false,
              },
            },
            component: () =>
              import('@/features/tour-drops/views/SurveyWrapper.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'picking',
    path: '/picking/:id',
    children: [
      {
        path: 'picking-start',
        name: 'picking-start',
        meta: {
          layout: 'Picking',
          activatedScreenWakeLock: true,
          showSameDayNotification: false,
          showCustomerCheckinBanner: false,
        },
        props: true,
        component: () =>
          import('@/features/picking/views/PickingStartedPage.vue'),
      },
      {
        path: 'overview',
        name: 'picking-order',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: true,
          showSameDayNotification: true,
          blockGoingBack: {
            targetRoutes: ['default', 'picking-start'],
            message: $t('components.router.picking-block-going-back.message'),
          },
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () => import('@/features/picking/views/PickingOrder.vue'),
      },
      {
        path: 'replacement/:orderItemId',
        name: 'replacement',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: true,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () => import('@/features/replacement/views/Replacement.vue'),
      },
      {
        path: 'complete-picking-note',
        name: 'complete-picking',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: true,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () =>
          import('@/features/picking/views/PickingCompleteNote.vue'),
      },
      {
        path: 'review',
        name: 'review-order',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () => import('@/features/review/views/ReviewOrder.vue'),
      },
      {
        path: 'bags',
        name: 'order-bags',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () => import('@/features/review/views/AssigningBags.vue'),
      },
      {
        path: 'labels',
        name: 'picking-labels',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () => import('@/features/review/views/AssigningLabels.vue'),
      },
      {
        path: 'bag-position',
        name: 'picking-bag-position',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          preventRedirectFromOrdersList: true,
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () => import('@/features/review/views/ScanBagPosition.vue'),
      },
      {
        path: 'complete',
        name: 'complete-picking-order',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          appCrashFeature: {
            trackRoute: true,
          },
          preventRedirectFromOrdersList: true,
        },
        props: true,
        component: () => import('@/features/review/views/CustomerNote.vue'),
      },
    ],
    meta: {
      showCustomerCheckinBanner: true,
    },
    component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
  },
  {
    name: 'handover',
    path: '/handover/:id',
    component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'overview',
        name: 'handover-order',
        meta: {
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          disabledLogout: true,
          appCrashFeature: {
            trackRoute: true,
          },
          preventRedirectFromOrdersList: true,
        },
        props: true,
        component: () => import('@/features/handover/views/HandoverOrder.vue'),
      },
      {
        path: 'customer-information',
        name: 'handover-customer-information',
        meta: {
          activatedScreenWakeLock: false,
          disabledLogout: true,
          showSameDayNotification: true,
          blockGoingBack: {
            targetRoutes: ['bags-collection'],
            message: $t('components.router.handover-block-going-back.message'),
          },
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () =>
          import('@/features/handover/views/CustomerInformation.vue'),
      },
      {
        path: 'bags-collection',
        name: 'bags-collection',
        meta: {
          activatedScreenWakeLock: false,
          appCrashFeature: {},
        },
        props: true,
        component: () => import('@/features/bags/views/BagsCollection.vue'),
      },
      {
        path: 'customer-note',
        name: 'handover-customer-note',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: true,
        },
        props: true,
        component: () =>
          import('@/features/ui/components/CustomerOverview.vue'),
      },
      {
        path: 'complete',
        name: 'complete-handover',
        meta: {
          layout: 'Picking',
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          disabledLogout: true,
          appCrashFeature: {
            trackRoute: true,
          },
          preventRedirectFromOrdersList: true,
        },
        props: true,
        component: () =>
          import('@/features/handover/views/HandoverComplete.vue'),
      },
      {
        path: '/age-verification/:id',
        name: 'age-verification',
        props: true,
        meta: {
          disabledLogout: true,
          showSameDayNotification: true,
        },
        component: () =>
          import('@/features/age-verification/views/AgeVerification.vue'),
      },
    ],
  },

  {
    path: '/user-report',
    name: 'user-report',
    component: () => import('@/features/user-reports/views/UserReport.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

export const router = ProviderPluginFactory.create<Router>({
  key: Symbol('Router'),
  defaultFactory: {
    create(app) {
      const router = createRouter({
        history: createWebHistory(),
        routes,
        scrollBehavior(to, from, savedPosition) {
          lastPosition = savedPosition
            ? savedPosition
            : {
                top: 0,
                left: 0,
              };
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(lastPosition);
            }, 10);
          });
        },
      });
      router.beforeEach(async (to, from, next) => {
        const authService = oauthServicePlugin.get();
        const isAuthenticated = await authService.isAuthenticated();
        if (to.name !== 'login' && !isAuthenticated) {
          next({ name: 'login' });
        } else {
          next();
        }
      });
      router.afterEach((to, from) => {
        if (shouldLogRouteChange(to, from)) {
          loggerServicePlugin
            .get()
            .warn(
              `Route changed from [${String(from.fullPath)}] to [${String(
                to.fullPath,
              )}]`,
            );
        }
      });
      app.use(router);
      return router;
    },
  },
});

export const routerConfigPlugin = MultiPluginFactory.with({
  router,
});

export default router;
