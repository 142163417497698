import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { PerformanceTrackerService } from './services/performance-tracker';
import { usePerformanceTracker } from './composables/usePerformanceTracker';

export const performanceTrackerServicePlugin =
  ProviderPluginFactory.create<PerformanceTrackerService>({
    key: Symbol('PerformanceTrackerService'),
    defaultFactory: {
      create: () => new PerformanceTrackerService(),
    },
  });

export const performanceTrackerExecutePlugin = ExecPluginFactory.fn(() => {
  const { clearOldTrackingData } = usePerformanceTracker();
  setInterval(() => {
    clearOldTrackingData();
  }, 1000 * 60);
});

export const performanceTrackerPlugin = MultiPluginFactory.with({
  performanceTrackerServicePlugin,
  performanceTrackerExecutePlugin,
});
