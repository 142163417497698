import { Ref } from 'vue';
import { cloneItem } from '@/utils/helpers/clone';
import { Storage, StorageReadOptions } from '@/features/core/storage';
import { ProductsService } from '@/features/products';
import {
  AnyEntity,
  entityRepositoryPlugin,
} from '@/features/core/entity-repository';
import { LoggerService, loggerServicePlugin } from '@/features/core/logger';
import { OrderMetadata, OrderMetadataService } from '@/features/orderMetadata';
import { isEntity } from '@/utils/helpers/isEntity';
import { OrderApiClient } from '../api';
import { Order } from '../entities';
import {
  OptionsEvents,
  OrderEventNames,
  OrderItem,
  OrderItemStatus,
  OrderLocalStatus,
} from '../types';
import {
  PerformanceThresholdEnum,
  UsePerformanceTracker,
} from '@/features/performance-tracker';

export class OrdersService {
  constructor(
    private storage: Storage,
    private orderApiClient: OrderApiClient,
    private productsService: ProductsService,
    private loggerService: LoggerService,
    private ordersMetadataService: OrderMetadataService,
    private performanceTracker: UsePerformanceTracker,
  ) {}

  async fetchOrdersByIds(
    ids: string[] | null = null,
    withWipe = true,
  ): Promise<Order[]> {
    const stringIds = ids ? ids.join(',') : 'all';
    this.performanceTracker.startTracking(
      `fetch-orders-by-ids-${stringIds}`,
      PerformanceThresholdEnum.INDEXED_DB_OPERATION +
        PerformanceThresholdEnum.API_CALL,
    );

    if (withWipe) {
      await this.clearAllOrders();
    }

    // Pull Orders
    const orders =
      ids === null
        ? await this.orderApiClient.getAll()
        : await this.orderApiClient.getByIds(ids);

    // Fetch required Products
    const productSkus = orders.flatMap((order) =>
      this.orderApiClient.getOrderItemSkus(order),
    );
    await this.productsService.fetchBySkus(productSkus);

    // Parse Orders with fetched data
    const parsedOrders = await this.orderApiClient.parseOrders(orders);

    // Save Orders
    const savedOrders = await this.storage.bulkSave(parsedOrders);

    // Setting up OrderMetadata
    await this.ordersMetadataService.checkAndSetOrdersMetadata(parsedOrders);

    this.performanceTracker.stopTracking(`fetch-orders-by-ids-${stringIds}`);

    return savedOrders;
  }

  async fetchAllOrders(withWipe = true): Promise<Order[]> {
    return this.fetchOrdersByIds(null, withWipe);
  }

  async clearAllOrders(): Promise<void> {
    await this.storage.removeAll(Order);
    await this.storage.removeAll(OrderMetadata);
  }

  getAllOrders(): Promise<Order[]> {
    return this.storage.getAll(Order);
  }

  async getOrdersCount(options?: StorageReadOptions<Order>): Promise<number> {
    return this.storage.count(Order, options);
  }

  async getOrderById(id: string): Promise<Ref<Order> | null> {
    const itemFilterRules = [this.itemHasProduct];

    const result = await entityRepositoryPlugin.get().getById(Order, {
      id,
    });

    if (result.value === undefined) {
      return null;
    }

    this.performanceTracker.startTracking(
      `get-order-by-id-${id}`,
      PerformanceThresholdEnum.SMALL_OPERATION,
    );
    result.value.items = result.value.items?.filter((item) =>
      itemFilterRules.every((fn) => fn(item)),
    );

    if (!result.value.items) {
      this.loggerService.warn(
        `Getting an order with no items ${result.value.id}`,
        result.value,
      );
    }
    this.performanceTracker.stopTracking(`get-order-by-id-${id}`);

    return result as Ref<Order>;
  }

  async trackEvent(
    order: Order,
    eventName: OrderEventNames,
    options: OptionsEvents = {},
  ): Promise<Order> {
    const currentDate = new Date().toISOString();
    if (!order.events) order.events = [];
    const existingEvent = order.events.find(
      (event) => event.name === eventName,
    );
    if (!existingEvent || options.allowMultipleTracking) {
      order.events.push({
        name: eventName,
        timestamp: currentDate,
      });
      if (options.skipSaving) return order;
      await this.saveOrder(Order.from(order));
    } else {
      if (options.skipLoggingOfDuplicateTracking) return order;
      loggerServicePlugin
        .get()
        .warn(
          `Event ${eventName} for order ${order.id} was skipped because it was already added`,
        );
    }
    return order;
  }

  async saveOrder(
    order: Order,
    scheduled = true,
    waitForSync = false,
  ): Promise<void> {
    this.performanceTracker.startTracking(
      `save-order-${order.id}`,
      PerformanceThresholdEnum.SMALL_OPERATION,
    );
    const fullOrderItems = cloneItem(
      order.items?.filter((item) => !this.isItemProductWasRemoved(item)),
    );
    const orderFromStorage = await this.storage.getById(Order, {
      id: order.id,
    });

    const restoredItems = orderFromStorage?.items
      .filter((item) => this.isItemProductWasRemoved(item))
      .map((item) => {
        if (
          item.status == OrderItemStatus.acknowledged ||
          item.status == OrderItemStatus.not_picked
        ) {
          item.quantity = 0;
          item.amount = 0;
          item.status = OrderItemStatus.picked;
        }
        return item;
      });

    if (!order?.items) {
      this.loggerService.warn(`Saving an order with no items ${order.id}`, {
        order,
        orderFromStorage,
      });
    }

    this.performanceTracker.stopTracking(`save-order-${order.id}`);

    const result = entityRepositoryPlugin.get().save(
      Order.from({
        ...order,
        items: restoredItems
          ? [...fullOrderItems, ...restoredItems]
          : fullOrderItems,
      }),
      { waitForSync },
    );
    if (scheduled) {
      await result.scheduled;
    }
    if (waitForSync) {
      await result.completed;
    }
  }

  async resetOrderItems(order: Order): Promise<void> {
    const orderClearedItems = order.items.map((orderItem) => ({
      ...orderItem,
      status: OrderItemStatus.not_picked,
      quantity: 0,
      amount: 0,
      weights: [],
    }));
    const result = entityRepositoryPlugin.get().save(
      Order.from({
        ...order,
        items: orderClearedItems,
      }),
    );
    await result.scheduled;
  }

  /**
   * Do not use it elsewhere or for other purposes.
   * A function required for SyncScheduler only.
   * Sends logs if the entity is ordered in 'SyncScheduler.schedule'.
   * This is passed as a callback in the Execution plugin (ordersExecutePlugin).
   */
  orderScheduleCallback(order: AnyEntity | undefined): void {
    if (
      order &&
      isEntity(order, Order.from({})) &&
      [
        OrderLocalStatus.HandoverReady,
        OrderLocalStatus.PickingReady,
        OrderLocalStatus.PickingCompleted,
        OrderLocalStatus.HandoverCompleted,
      ].includes(order.localStatus)
    ) {
      this.loggerService.info(`[${order.id}] Sync scheduler added entity`, {
        snapshot: {
          id: order.id,
          items: order.items,
          localStatus: order.localStatus,
          actionStatuses: order.actionStatuses,
          events: order.events,
        },
      });
    }
  }

  private isItemProductWasRemoved(item: OrderItem): boolean {
    return typeof item.product === 'string';
  }

  private itemHasProduct = (item: OrderItem): boolean => {
    return Boolean(item.product && typeof item.product === 'object');
  };
}
