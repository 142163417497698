<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.44034 1.76335C8.35321 1.60117 8.184 1.5 7.99991 1.5C7.81581 1.5 7.6466 1.60117 7.55946 1.76335L1.55955 12.9304C1.47629 13.0854 1.48059 13.2727 1.57086 13.4237C1.66112 13.5747 1.82409 13.6671 1.99999 13.6671H14.4998C14.4998 13.1148 14.0521 12.6671 13.4998 12.6671H2.83624L7.99991 3.05644L11.6847 9.91455L12.0584 10.6175C12.3177 11.1051 12.9232 11.2902 13.4108 11.031L12.966 10.1943C12.9586 10.1755 12.9501 10.1569 12.9403 10.1387L12.5666 9.44311L12.4719 9.26499L12.4707 9.26464L8.44034 1.76335Z"
      fill="currentColor"
    />
    <path
      d="M8.44034 1.76335C8.35321 1.60117 8.184 1.5 7.99991 1.5C7.81581 1.5 7.6466 1.60117 7.55946 1.76335L1.55955 12.9304C1.47629 13.0854 1.48059 13.2727 1.57086 13.4237C1.66112 13.5747 1.82409 13.6671 1.99999 13.6671H14.4998C14.4998 13.1148 14.0521 12.6671 13.4998 12.6671H2.83624L7.99991 3.05644L11.6847 9.91455L12.0584 10.6175C12.3177 11.1051 12.9232 11.2902 13.4108 11.031L12.966 10.1943C12.9586 10.1755 12.9501 10.1569 12.9403 10.1387L12.5666 9.44311L12.4719 9.26499L12.4707 9.26464L8.44034 1.76335Z"
      fill="black"
      fill-opacity="0.5"
    />
    <path
      d="M7.99991 6.50019C8.27604 6.50019 8.4999 6.72405 8.4999 7.00021V9.16695C8.4999 9.44311 8.27604 9.66697 7.99991 9.66697C7.72377 9.66697 7.49991 9.44311 7.49991 9.16695V7.00021C7.49991 6.72405 7.72377 6.50019 7.99991 6.50019Z"
      fill="currentColor"
    />
    <path
      d="M7.99991 6.50019C8.27604 6.50019 8.4999 6.72405 8.4999 7.00021V9.16695C8.4999 9.44311 8.27604 9.66697 7.99991 9.66697C7.72377 9.66697 7.49991 9.44311 7.49991 9.16695V7.00021C7.49991 6.72405 7.72377 6.50019 7.99991 6.50019Z"
      fill="black"
      fill-opacity="0.5"
    />
    <path
      d="M8.01651 10.3337C7.90114 10.3337 7.78836 10.3679 7.69243 10.432C7.5965 10.4961 7.52174 10.5872 7.47758 10.6938C7.43343 10.8004 7.42188 10.9177 7.44439 11.0308C7.4669 11.144 7.52245 11.2479 7.60403 11.3295C7.68561 11.4111 7.78955 11.4667 7.9027 11.4892C8.01586 11.5117 8.13315 11.5001 8.23973 11.456C8.34632 11.4118 8.43743 11.337 8.50152 11.2411C8.56562 11.1452 8.59983 11.0324 8.59983 10.917C8.59983 10.7623 8.53837 10.6139 8.42898 10.5045C8.31958 10.3951 8.17121 10.3337 8.01651 10.3337Z"
      fill="currentColor"
    />
    <path
      d="M8.01651 10.3337C7.90114 10.3337 7.78836 10.3679 7.69243 10.432C7.5965 10.4961 7.52174 10.5872 7.47758 10.6938C7.43343 10.8004 7.42188 10.9177 7.44439 11.0308C7.4669 11.144 7.52245 11.2479 7.60403 11.3295C7.68561 11.4111 7.78955 11.4667 7.9027 11.4892C8.01586 11.5117 8.13315 11.5001 8.23973 11.456C8.34632 11.4118 8.43743 11.337 8.50152 11.2411C8.56562 11.1452 8.59983 11.0324 8.59983 10.917C8.59983 10.7623 8.53837 10.6139 8.42898 10.5045C8.31958 10.3951 8.17121 10.3337 8.01651 10.3337Z"
      fill="black"
      fill-opacity="0.5"
    />
  </svg>
</template>
<script setup lang="ts"></script>
