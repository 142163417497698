<template>
  <div class="default-layout" :class="{ layout__with_banner: showBanner }">
    <div class="container">
      <RouterView v-if="withRouter" />
      <slot></slot>
    </div>
  </div>
  <Banner :visible="showBanner" @close="goToOrder">
    <div class="banner__title">
      {{ $t('components.checkin-banner.title.text') }}
    </div>
    <div class="banner__subtitle">
      {{
        $t('components.checkin-banner.description.text', {
          pickupCode: firstCheckedInOrder?.pickupCode,
        })
      }}
    </div>
  </Banner>
  <Dialog
    :visible="showDialog"
    :confirm-text="$t('components.pause-dialog.confirm')"
    :title-text="$t('components.pause-dialog.title')"
    :content-text="$t('components.pause-dialog.content')"
    @confirmed="onConfirmed"
    @canceled="onCancel"
  />
  <ConnectionDialog
    :visible="visibleConnectionDialog"
    @close="closeConnectionDialog"
  />
  <VerificationDialog :visible="visibleVerificationDialog" />
  <DynamicDialog />

  <Teleport to="body">
    <KeepAlive>
      <Transmissions
        v-if="isTransmissionsWindowVisible"
        @close="() => toggleTransmissionsWindowVisibility(false)"
      />
    </KeepAlive>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { RouterView, useRoute, useRouter } from 'vue-router';
import { Banner, Dialog } from '@/features/ui';
import {
  Order,
  OrderEventNames,
  ordersServicePlugin,
  setPickingEventsAsAlreadyPatched,
  useCheckInOrders,
} from '@/features/orders';
import { storagePlugin } from '@/features/core/storage';
import { deepClone } from '@/utils/helpers/deepClone';
import { RedirectToCheckinNotifyEvent } from '@/features/orders/checkin';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { authServicePlugin } from '@/features/core/auth';
import VerificationDialog from '@/features/ui/components/VerificationDialog.vue';
import ConnectionDialog from '@/features/ui/components/ConnectionDialog.vue';
import { VerificationDialogShow } from '@/features/login/events';
import DynamicDialog from '../components/DynamicDialog.vue';
import {
  EntityType,
  EntityUpdatedEvent,
} from '@/features/core/entity-repository';
import { loggerServicePlugin } from '@/features/core/logger';
import { EntityUpdate } from '@/features/service-worker';
import { Transmissions, useTransmissions } from '@/features/transmissions';
import { $t } from '@/i18n';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    VerificationDialog,
    ConnectionDialog,
    RouterView,
    Dialog,
    Banner,
    DynamicDialog,
    Transmissions,
  },
  props: {
    withRouter: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { startTracking } = usePerformanceTracker();
    const visibleConnectionDialog = ref(false);
    const visibleVerificationDialog = ref(false);

    const { showOfflineDialog } = authServicePlugin.get();

    const {
      toggleTransmissionsWindowVisibility,
      isTransmissionsWindowVisible,
    } = useTransmissions();

    watch(showOfflineDialog, () => {
      if (showOfflineDialog.value) {
        visibleConnectionDialog.value = true;
      }
    });

    eventBusServicePlugin.get().on(VerificationDialogShow, () => {
      visibleVerificationDialog.value = true;
    });

    const closeConnectionDialog = () => {
      visibleConnectionDialog.value = false;
    };

    const { checkCheckedInOrders, firstCheckedInOrder, showBanner } =
      useCheckInOrders();

    onMounted(() => {
      void checkCheckedInOrders();
    });

    watch(
      () => notificationPlugin.get().isShowMessage.value,
      async () => {
        await checkCheckedInOrders();
      },
    );

    const goToCheckinOrder = async (): Promise<void> => {
      if (!firstCheckedInOrder.value) return;

      startTracking(
        `checkin-banner-clicked`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      );

      loggerServicePlugin
        .get()
        .info(
          `CheckIn banner clicked [order ${firstCheckedInOrder.value.id}]`,
          { currentRoute: route.fullPath },
        );
      firstCheckedInOrder.value.events = setPickingEventsAsAlreadyPatched(
        firstCheckedInOrder.value.events,
      );
      const orderWithEventList = await ordersServicePlugin
        .get()
        .trackEvent(firstCheckedInOrder.value, OrderEventNames.pickup_started, {
          skipSaving: true,
        });

      let cloneOrder;
      try {
        cloneOrder = deepClone(orderWithEventList);
      } catch (e) {
        loggerServicePlugin.get().error(e as Error, orderWithEventList);

        notificationPlugin.get().show({
          text: $t('errors.checkin.wrong-data.text'),
          type: NotificationType.Error,
        });

        return;
      }

      await storagePlugin.get().save(Order.from(cloneOrder));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const entityUpdateData: EntityUpdate<EntityType<any>>[] = [
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          entity: Order as EntityType<any>,
          ids: [orderWithEventList.id],
          updated: true,
        },
      ];

      eventBusServicePlugin
        .get()
        .emit(new EntityUpdatedEvent(entityUpdateData));

      await router.push(
        firstCheckedInOrder.value.cartNote
          ? {
              name: 'handover-customer-note',
              params: {
                id: firstCheckedInOrder.value.id,
              },
            }
          : {
              name: 'bags-collection',
              params: {
                id: firstCheckedInOrder.value.id,
              },
            },
      );
    };
    const showDialog = ref(false);

    const goToOrder = async (): Promise<void> => {
      if (route.name === 'default') {
        await goToCheckinOrder();
      } else {
        showDialog.value = true;
      }
    };

    const onConfirmed = async (): Promise<void> => {
      eventBusServicePlugin.get().emit(new RedirectToCheckinNotifyEvent());

      showDialog.value = false;
      await goToCheckinOrder();
    };

    const onCancel = (): void => {
      showDialog.value = false;
    };

    return {
      showBanner,
      goToOrder,
      onConfirmed,
      showDialog,
      onCancel,
      firstCheckedInOrder,
      visibleConnectionDialog,
      closeConnectionDialog,
      visibleVerificationDialog,
      isTransmissionsWindowVisible,
      toggleTransmissionsWindowVisibility,
    };
  },
});
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  .container {
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
  }

  &.layout__with_banner {
    padding-bottom: 95px;
  }
}
</style>
