import { OrderItemStatus, OrdersService } from '@/features/orders';
import { Order } from '@/features/orders/entities';
import { OrderNotFoundError } from '@/features/orders/errors';
import { useFeatureToggle } from '@/features/feature-toggle';
import { SyncSchedulerService } from '@/features/sync-scheduler';
import { Storage } from '@/features/core/storage';
import { updateOrderTimestamp } from '@/utils/helpers/updateOrderTimestamp';
import {
  EntityRepository,
  StorageEntityRepository,
} from '@/features/core/entity-repository';

export class PickingOrderService {
  constructor(
    private ordersService: OrdersService,
    private syncSchedulerService: SyncSchedulerService,
    private storage: Storage,
    private entityRepository: EntityRepository,
  ) {}

  async findOrder(orderReference: string): Promise<Order> {
    const dropOrder = await this.getOrderByReference(orderReference);

    if (!dropOrder) {
      throw new OrderNotFoundError({
        orderReference,
      });
    }

    return dropOrder;
  }

  private async getOrderByReference(
    orderReference: string,
  ): Promise<Order | undefined> {
    const result = await this.entityRepository.getAll(Order, {
      filter: {
        orderReference: {
          equals: orderReference,
        },
      },
    });
    return result.value?.[0];
  }

  updateOrderItems(dropOrder: Order): void {
    const { featureList } = useFeatureToggle();

    dropOrder.items.forEach((item) => {
      if (featureList.value.manipulateOrderStates.active) {
        item.quantity = item.quantityOriginal;
        item.amount = item.amountOriginal;
        item.status = OrderItemStatus.staged;
        item.weights = [
          {
            weight: item.amountOriginal,
            relatedBarcode: item.product.sku,
          },
        ];
      }

      if (
        item.status.toLowerCase() !== OrderItemStatus.cancelled &&
        item.status.toLowerCase() !== OrderItemStatus.rejected
      ) {
        item.status = OrderItemStatus.staged;
      }
    });
  }

  async reloadOrders(): Promise<void> {
    const pendingSyncs = await this.syncSchedulerService.getPending();
    if (pendingSyncs.length > 0) return;

    await this.ordersService.fetchAllOrders();

    await (this.entityRepository as StorageEntityRepository).updateEntity([
      {
        entity: Order,
        updated: true,
      },
    ]);
  }

  async saveOrder(dropOrder: Order): Promise<Order> {
    updateOrderTimestamp(dropOrder);
    return await this.storage.save(
      Order.from(JSON.parse(JSON.stringify(dropOrder))),
    );
  }
}
