<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.37508 6.45884C9.37508 6.11365 9.6549 5.83382 10.0001 5.83382C10.3453 5.83382 10.6251 6.11365 10.6251 6.45884V11.0423C10.6251 11.3875 10.3453 11.6674 10.0001 11.6674C9.6549 11.6674 9.37508 11.3875 9.37508 11.0423V6.45884ZM10.4052 12.832C10.2853 12.7518 10.1443 12.7091 10.0001 12.7091C9.80669 12.7091 9.62123 12.7859 9.48448 12.9226C9.34774 13.0594 9.27091 13.2449 9.27091 13.4383C9.27091 13.5825 9.31368 13.7235 9.3938 13.8434C9.47392 13.9633 9.5878 14.0568 9.72104 14.112C9.85428 14.1671 10.0009 14.1816 10.1423 14.1535C10.2838 14.1253 10.4137 14.0559 10.5157 13.9539C10.6177 13.8519 10.6871 13.722 10.7152 13.5805C10.7434 13.4391 10.7289 13.2925 10.6737 13.1592C10.6186 13.026 10.5251 12.9121 10.4052 12.832ZM10.0001 2.91704C6.08806 2.91704 2.91675 6.08847 2.91675 10.0006C2.91675 10.9789 3.11467 11.9093 3.47207 12.7552C3.51126 12.848 3.55238 12.9397 3.59538 13.0304C3.84177 13.5503 3.72161 14.195 3.24353 14.515L3.07041 14.6309C2.96941 14.48 2.87319 14.3256 2.78198 14.1679C2.69441 14.0165 2.61145 13.8621 2.53331 13.7048C2.45787 13.553 2.38692 13.3986 2.32062 13.2417C1.89936 12.2446 1.66675 11.149 1.66675 10.0006C1.66675 5.39809 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39809 18.3334 10.0006C18.3334 14.6032 14.6025 18.3343 10.0001 18.3343C8.85178 18.3343 7.75621 18.1017 6.75915 17.6804C6.60225 17.6141 6.44781 17.5431 6.29601 17.4677C6.13876 17.3895 5.98435 17.3066 5.83296 17.219C5.66728 17.1232 5.50523 17.0218 5.34706 16.9152L5.46352 16.7424C5.78511 16.2654 6.43013 16.1473 6.94917 16.3955C7.04678 16.4421 7.14563 16.4866 7.24565 16.5289C8.09154 16.8863 9.02189 17.0842 10.0001 17.0842C13.9121 17.0842 17.0834 13.9128 17.0834 10.0006C17.0834 6.08847 13.9121 2.91704 10.0001 2.91704Z"
    />
  </svg>
</template>
