import { Router } from 'vue-router';
import { ErrorHandler } from '@/features/core/errors';
import { LoggerService } from '@/features/core/logger';
import {
  useDrops,
  useDropsCleveron,
  useDropsMiddlemile,
} from '@/features/tour-drops/composables';
import { TourDrop } from '@/features/tour-drops';
import {
  DeliveryType,
  NotAllowedToAccessRouteWithNotActiveDrop,
  NotAllowedToAccessRouteWithNotActiveTour,
  Tour,
} from '..';
import { useTours } from '../composables';
import { ToursService } from './tours-service';

export class DriverAppInitService {
  constructor(
    private toursService: ToursService,
    private router: Router,
    private errorHandler: ErrorHandler,
    private loggerService: LoggerService,
  ) {}

  async init(): Promise<void> {
    const { tourId, dropId } = this.router.currentRoute.value.params;

    await this.toursService.loadAllTours();

    try {
      this.initCurrentActiveTour(tourId as string);
    } catch (e) {
      this.errorHandler.handle(e);
      if (e instanceof NotAllowedToAccessRouteWithNotActiveTour) {
        await this.redirectTours();
        return;
      }
    }

    await this.loadDrops(tourId as string);

    try {
      this.initCurrentActiveDrop(dropId as string);
    } catch (e) {
      this.errorHandler.handle(e);
      if (e instanceof NotAllowedToAccessRouteWithNotActiveDrop) {
        await this.redirectTourDrops(tourId as string);
        return;
      }
    }
  }

  showToursRoute(): boolean {
    const { tourId } = this.router.currentRoute.value.params;
    const { currentTour } = useTours();
    return !tourId || !!currentTour.value;
  }

  showDropsRoute(): boolean {
    const { dropId } = this.router.currentRoute.value.params;
    const { currentTour } = useTours();
    if (currentTour.value) {
      const composable = this.getDropComposable(currentTour.value.deliveryMode);
      if (composable) {
        const { currentActiveDrop } = composable();
        return !dropId || !!currentActiveDrop.value;
      }
    }
    return !dropId;
  }

  private async loadDrops(tourId?: string): Promise<void> {
    const { currentTour } = useTours();
    if (tourId && currentTour.value) {
      const { loadDrops } =
        currentTour.value.deliveryMode === DeliveryType.HOME_DELIVERY ||
        currentTour.value.deliveryMode === DeliveryType.MIDDLEMILE
          ? useDrops()
          : useDropsCleveron();
      await loadDrops();
    }
  }

  private initCurrentActiveTour(tourId?: string): void {
    const { activeTours } = useTours();
    if (activeTours.value.length > 1) {
      this.loggerService.error('Multiple active tours');
    }
    if (tourId) {
      const activeTour = activeTours.value.find((tour) => tour.id === tourId);
      if (activeTour) {
        this.setCurrentTour(activeTour);
      } else {
        throw new NotAllowedToAccessRouteWithNotActiveTour();
      }
    } else if (activeTours.value.length) {
      this.setCurrentTour(activeTours.value[0]);
    }
  }

  private setCurrentTour(tour: Tour) {
    const { currentTour } = useTours();
    currentTour.value = tour;
  }

  private initCurrentActiveDrop(dropId?: string): void {
    const { currentTour } = useTours();
    if (currentTour.value) {
      const composable = this.getDropComposable(currentTour.value.deliveryMode);
      if (composable) {
        const { activeDrops } = composable();
        if (activeDrops.value.length > 1) {
          this.loggerService.error('Multiple active drops');
        }
        const activeDrop = activeDrops.value.find(
          (drop) => drop.reference === dropId,
        );
        if (dropId) {
          if (activeDrop) {
            this.setCurrentDrop(activeDrop);
          } else {
            throw new NotAllowedToAccessRouteWithNotActiveDrop();
          }
        } else if (activeDrops.value.length) {
          this.setCurrentDrop(activeDrops.value[0]);
        }
      }
    }
  }

  private setCurrentDrop(drop: TourDrop) {
    const { currentTour } = useTours();
    if (currentTour.value) {
      const composable = this.getDropComposable(currentTour.value.deliveryMode);
      if (composable) {
        const { setCurrentActiveDrop } = composable();
        setCurrentActiveDrop(drop);
      }
    }
  }

  private getDropComposable(deliveryMode: DeliveryType) {
    return {
      [DeliveryType.HOME_DELIVERY]: useDrops,
      [DeliveryType.CLEVERON]: useDropsCleveron,
      [DeliveryType.MIDDLEMILE]: useDropsMiddlemile,
    }[deliveryMode];
  }

  private async redirectTours() {
    await this.router.push({
      name: 'tours',
    });
  }

  private async redirectTourDrops(tourId: string) {
    await this.router.push({
      name: 'tour-drops',
      params: {
        tourId,
      },
    });
  }
}
