import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import {
  DeliveryItem,
  DeliveryType,
  TourOriginAddress,
  TourStatus,
} from '../types';
import { DeliveryUnit, TourDrop } from '@/features/tour-drops/types';

export class NewTour extends RestorableEntity implements NewEntity {
  type = 'tour';
}

export class TourItem extends NewTour implements Entity {
  id!: string;
  label!: string;
  deliveryType!: string;
  deliveryMode!: DeliveryType;
  reference!: string;
  status!: TourStatus;
  statusChangedAt?: string;
  vehicle!: {
    id: string;
    licensePlate: string;
  };
  assignedDriver!: {
    id: string;
  };
  deliveryUnits!: DeliveryUnit[];
  deliveryItems!: DeliveryItem[];
  drops!: TourDrop[];
  action?: string;
  actions?: string[];
  startTime!: string;
  endTime!: string;
  loadingArea!: string;
  originAddress!: TourOriginAddress;
}

export class TourStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Tour_1).stores({
      [`${storeName}`]: '&id, label, status, actions, startTime, endTime',
    });
    version(DBVersions.Tour_2).stores({
      [`${storeName}`]:
        '&id, label, status, statusChangedAt, actions, startTime, endTime',
    });

    return Promise.resolve();
  }
}
