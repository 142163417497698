import { MultiPluginFactory } from '@/features/core/plugin';
import { toursApiPlugin, toursApiRegistryPlugin } from './api';
import {
  toursDeliveryUnitSortingServicePlugin,
  toursNotificationProcessorPlugin,
  toursOrderAdapterServicePlugin,
  tourStatusToActionServicePlugin,
  driverAppInitServicePlugin,
  toursServicePlugin,
} from './services';

import { toursEntityPlugin } from './entities';

export * from './services';
export * from './types';
export * from './api';
export * from './entities';
export * from './composables';
export * from './errors';

export const toursPlugin = MultiPluginFactory.with({
  toursApiPlugin,
  toursApiRegistryPlugin,
  toursOrderAdapterServicePlugin,
  toursServicePlugin,
  toursEntityPlugin,
  toursNotificationProcessorPlugin,
  toursDeliveryUnitSortingServicePlugin,
  tourStatusToActionServicePlugin,
  driverAppInitServicePlugin,
});
