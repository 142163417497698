import { Sync } from '@/features/sync-scheduler';
import { Order, ordersServicePlugin } from '@/features/orders';
import { storagePlugin } from '@/features/core/storage';
import { toursServicePlugin } from '@/features/tours';
import { orderMetadataServicePlugin } from '@/features/orderMetadata';
import { dataFetchQueueServicePlugin } from '@/features/data-fetch-queue';

interface RefreshOptions {
  refreshOrders?: boolean;
  refreshTours?: boolean;
  removeSyncData?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useProfileReceiveData() {
  const refreshAll = async (options: RefreshOptions = {}): Promise<void> => {
    options = {
      refreshOrders: false,
      refreshTours: false,
      removeSyncData: true,
      ...options,
    };

    if (options.refreshOrders) {
      const orders = await ordersServicePlugin.get().fetchAllOrders();
      await orderMetadataServicePlugin.get().clearAll();
      await orderMetadataServicePlugin.get().checkAndSetOrdersMetadata(orders);
      await dataFetchQueueServicePlugin.get().removeAllOfEntity(Order);
    }

    if (options.removeSyncData) {
      await storagePlugin.get().removeAll(Sync);
    }

    if (options.refreshTours) {
      await toursServicePlugin.get().fetchAllTours();
    }
  };
  return {
    refreshAll,
  };
}
