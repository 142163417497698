export interface DataBackgroundSyncService {
  fetchDataOnBackgroundSync(): Promise<void>;
}

export enum BackgroundSyncTags {
  NormalBackgroundSync = 'normal-background-sync',
  PeriodicBackgroundSync = 'periodic-background-sync',
  UpdateEntityOrder = 'update-entity-order',
  UpdateEntityProduct = 'update-entity-product',
  UpdateEntityTour = 'update-entity-tour',
  UpdateEntityTourDrop = 'update-entity-tour-drop',
  UpdateEntitySurveyAnswer = 'update-entity-survey-answer',
}
