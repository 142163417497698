import { Product } from '@/features/products';
import { $t } from '@/i18n';

export interface TemperatureClass {
  name: string | string[];
  label: string;
  active: boolean;
  color: string;
}

export interface BasicTemperatureClass {
  name: string;
  label: string;
  active: boolean;
  color: string;
}

export interface ProductResponse extends Omit<Product, 'ageRestricted'> {
  ageRestriction: number;
}

export interface ProductResponseItem {
  attributes: ProductResponse;
}

export enum TemperatureClassList {
  fresh = 'fresh',
  chiller = 'chiller',
  freezer = 'freezer',
  ambient = 'ambient',
  frozen = 'frozen',
}
export enum Labels {
  chiller = 'chiller',
  shelf = 'shelf',
  freezer = 'freezer',
}
export const HomeDeliveryTemperatureClassListOrder = {
  [TemperatureClassList.ambient]: 0,
  [TemperatureClassList.chiller]: 1,
  [TemperatureClassList.fresh]: 1,
  [TemperatureClassList.freezer]: 2,
  [TemperatureClassList.frozen]: 2,
};

export const CleveronTemperatureClassListOrder = {
  [TemperatureClassList.frozen]: 0,
  [TemperatureClassList.freezer]: 0,
  [TemperatureClassList.ambient]: 1,
  [TemperatureClassList.chiller]: 1,
  [TemperatureClassList.fresh]: 1,
};

export const TemperatureClassLabels = [
  {
    TemperatureClassName: TemperatureClassList.fresh,
    label: Labels.shelf,
  },
  {
    TemperatureClassName: TemperatureClassList.ambient,
    label: Labels.shelf,
  },
  {
    TemperatureClassName: TemperatureClassList.frozen,
    label: Labels.freezer,
  },
  {
    TemperatureClassName: TemperatureClassList.freezer,
    label: Labels.freezer,
  },
  {
    TemperatureClassName: TemperatureClassList.chiller,
    label: Labels.chiller,
  },
];

export const basicTemperatureClasses = [
  {
    name: TemperatureClassList.fresh,
    label: $t('components.products-types.temperature-classes.fresh.text'),
    active: false,
    color: '#8EB826',
  },
  {
    name: TemperatureClassList.chiller,
    label: $t('components.products-types.temperature-classes.chiller.text'),
    active: false,
    color: '#FF7800',
  },
  {
    name: TemperatureClassList.freezer,
    label: $t('components.products-types.temperature-classes.freezer.text'),
    active: false,
    color: '#55C3F0',
  },
  {
    name: TemperatureClassList.ambient,
    label: $t('components.products-types.temperature-classes.ambient.text'),
    active: false,
    color: '#FFC800',
  },
].map((i) => Object.freeze(i));

export enum HandoverTemperatureClassList {
  shelf = 'shelf',
  chiller = 'chiller',
  freezer = 'freezer',
  ambient = 'ambient',
  fresh = 'fresh',
}

export const basicHandoverTypes = [
  {
    name: HandoverTemperatureClassList.shelf,
    label: $t('components.products-types.handover-types.shelf.text'),
    active: true,
    color: '#8EB826',
  },
  {
    name: HandoverTemperatureClassList.chiller,
    label: $t('components.products-types.handover-types.chiller.text'),
    active: true,
    color: '#FFC800',
  },
  {
    name: HandoverTemperatureClassList.freezer,
    label: $t('components.products-types.handover-types.freezer.text'),
    active: true,
    color: '#55C3F0',
  },
];
