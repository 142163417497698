/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access  */
import { LoggerService } from '@/features/core/logger';
import { arrayHasEmptyValues } from '@/utils/helpers/arrayHasEmptyValues';
import { nonNullable } from '@/utils/helpers/non-nullable';
import { getPath } from '@/utils/helpers/path';
import { InferType } from '@/utils/types';
import { removeFunctionFromObj } from '@/utils/helpers/removeFunctionFromObj';
import { Collection, Dexie, Table } from 'dexie';
import { getStorableType, RestorableType, Storable } from '../storable';
import {
  Storage,
  StorageFilter,
  StorageFilters,
  StorageReadIdOptions,
  StorageReadIdsOptions,
  StorageReadOptions,
  StorageSortDirection,
  StorageWriteOptions,
} from '../storage';

export class DexieStorage implements Storage {
  constructor(private loggerService: LoggerService) {}
  private resolve!: (db: Dexie) => void;
  private db = new Promise<Dexie>((resolve) => (this.resolve = resolve));

  async init(db: Dexie): Promise<void> {
    await db.open();
    this.resolve(db);
  }

  async getById<T extends RestorableType<any>>(
    type: T,
    options: StorageReadIdOptions,
  ): Promise<InferType<T> | undefined> {
    if (!options.id) {
      this.handleError(
        null,
        type,
        `ID was not provided during getById from dexie storage`,
        options,
      );
      return;
    }

    const table = await this.getTable(type);
    const data = await table.get(options.id).catch((error) => {
      this.handleError(
        error,
        type,
        `Error in getById from dexie storage`,
        options,
      );
    });

    if (data) {
      return type.from(data);
    }
  }

  async getByIds<T extends RestorableType<any>>(
    type: T,
    options: StorageReadIdsOptions,
  ): Promise<InferType<T>[]> {
    if (!options.ids || arrayHasEmptyValues(options.ids)) {
      this.handleError(
        null,
        type,
        `Some of IDs were not provided during getByIds from dexie storage`,
        options,
      );
    }

    const table = await this.getTable(type);
    const data = await table.bulkGet(options.ids).catch((error) => {
      this.handleError(
        error,
        type,
        `Error in getByIds from dexie storage`,
        options,
      );
    });

    return data ? data.filter(nonNullable).map((d) => type.from(d)) : [];
  }

  async getAll<T extends RestorableType<any>>(
    type: T,
    options?: StorageReadOptions,
  ): Promise<InferType<T>[]> {
    const table = await this.getTable(type);
    const data = await this.collateAndSortTable(table, options);

    return data.map((d) => type.from(d));
  }

  async count(
    type: RestorableType<any>,
    options?: StorageReadOptions,
  ): Promise<number> {
    const table = await this.getTable(type);
    const collection = await this.collateAndSortTable(table, options);

    return collection.length;
  }

  async save<T extends Storable>(data: T): Promise<T> {
    const type = getStorableType(data);

    if (!data.id) {
      this.handleError(
        null,
        type,
        `ID was not provided during save to dexie storage`,
        data,
      );
    }
    const table = await this.getTable(type);
    data = type.from(removeFunctionFromObj(data));
    data.id = String(
      await table.put(data).catch((error) => {
        this.handleError(error, type, `Error in save to dexie storage`, data);
      }),
    );

    return data;
  }

  async bulkSave<T extends Storable>(dataArr: T[]): Promise<T[]> {
    if (!dataArr.length) return [];

    const type = getStorableType(dataArr);
    const dataArrIds = dataArr.map((data) => data.id);
    if (arrayHasEmptyValues(dataArrIds)) {
      this.handleError(
        null,
        type,
        `Some of IDs were not provided during bulkSave to dexie storage`,
        dataArr,
      );
    }

    const table = await this.getTable(type);
    let parsedDataArr = dataArr.map((data) =>
      type.from(removeFunctionFromObj(data)),
    );

    const keys = await table
      .bulkPut(parsedDataArr, {
        allKeys: true,
      })
      .catch((error) => {
        this.handleError(
          error,
          type,
          `Error in bulkSave to dexie storage`,
          parsedDataArr,
        );
        throw error;
      });

    parsedDataArr = parsedDataArr.map((parsedData, index) => {
      parsedData.id = String(keys[index]);
      return parsedData;
    });

    return parsedDataArr;
  }

  async remove(data: Storable): Promise<void> {
    const type = getStorableType(data);

    if (!data.id) {
      this.handleError(
        null,
        type,
        `ID was not provided during remove from dexie storage`,
        data,
      );
    }
    const table = await this.getTable(type);
    await table.delete(data.id).catch((error) => {
      this.handleError(error, type, `Error in remove from dexie storage`, data);
    });
  }

  async removeSeveral(
    type: RestorableType<any>,
    options: Required<Pick<StorageWriteOptions, 'ids'>>,
  ): Promise<void> {
    if (!options.ids || arrayHasEmptyValues(options.ids)) {
      this.handleError(
        null,
        type,
        `Some of IDs were not provided during removeSeveral from dexie storage`,
        options,
      );
    }

    const table = await this.getTable(type);
    await table.bulkDelete(options.ids).catch((error) => {
      this.handleError(
        error,
        type,
        `Error in remove several orders from dexie storage`,
        options,
      );
    });
  }

  async removeAll(type: RestorableType<any>): Promise<void> {
    const table = await this.getTable(type);
    await table.clear();
  }

  private async getTable<T extends RestorableType<any>>(type: T) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const storeName = new type().type;
    const db = await this.db;
    return db.table<InferType<T>>(storeName);
  }

  private async collateAndSortTable<T>(
    table: Table<T>,
    options?: StorageReadOptions,
  ) {
    let collection = table.toCollection();
    if (options?.filter) {
      collection = this.filterTable(table, options.filter);
    }

    let data: T[];

    if (options?.sortBy) {
      data = await collection.sortBy(options.sortBy);
    } else {
      data = await collection.toArray();
    }

    if (options?.sortDir === StorageSortDirection.DESC) {
      data = data.reverse();
    }

    if (options?.offset || options?.limit) {
      data = data.slice(options?.offset, options?.limit);
    }

    return data;
  }

  private filterTable<T>(table: Table<T>, filters?: StorageFilters) {
    if (!filters) {
      return table.toCollection();
    }

    // TODO: Implement AND/OR groups of filters
    const [primaryKey, ...secondaryKeys] = Object.keys(filters);

    let collection = this.applyPrimaryTableFilter(
      table,
      primaryKey,
      (filters as any)[primaryKey],
    );

    if (secondaryKeys.length > 0) {
      collection = this.applySecondaryFilters(
        collection,
        Object.fromEntries(secondaryKeys.map((k) => [k, (filters as any)[k]])),
      );
    }

    return collection.distinct();
  }

  private applyPrimaryTableFilter<T>(
    table: Table<T>,
    key: string,
    filter: StorageFilter,
  ) {
    if ('equals' in filter && filter.equals !== undefined) {
      if (typeof filter.equals === 'string') {
        return table.where(key).equalsIgnoreCase(filter.equals);
      } else {
        return table.where(key).equals(filter.equals);
      }
    }

    if ('notEquals' in filter && filter.notEquals !== undefined) {
      return table.where(key).notEqual(filter.notEquals);
    }

    if ('anyOf' in filter && filter.anyOf !== undefined) {
      if (filter.anyOf.every((v) => typeof v === 'string')) {
        return table.where(key).anyOfIgnoreCase(filter.anyOf as string[]);
      } else {
        return table.where(key).anyOf(filter.anyOf);
      }
    }

    if ('noneOf' in filter && filter.noneOf !== undefined) {
      return table.where(key).noneOf(filter.noneOf);
    }

    if ('match' in filter && filter.match !== undefined) {
      return table
        .where(key)
        .between(filter.match, filter.match + '\uffff', true, true);
    }

    if ('startsWith' in filter && filter.startsWith !== undefined) {
      return table.where(key).startsWith(filter.startsWith);
    }

    if (
      'from' in filter &&
      filter.from !== undefined &&
      filter.to !== undefined
    ) {
      return table.where(key).between(filter.from, filter.to, true, true);
    }

    return table.toCollection();
  }

  private applySecondaryFilters<T>(
    collection: Collection<T>,
    filters: StorageFilters,
  ) {
    const filterFns: ((v: T) => boolean)[] = Object.entries(filters).map(
      ([key, filter]) => this.getFilterFn(key as any, filter),
    );

    return collection.and((v) => filterFns.every((f) => f(v)));
  }

  private getFilterFn(key: string, filter: StorageFilter): (v: any) => boolean {
    if ('equals' in filter && filter.equals !== undefined) {
      const equals = this.toComparable(filter.equals);
      return (v) => this.toComparable(getPath(v, key)) === equals;
    }

    if ('notEquals' in filter && filter.notEquals !== undefined) {
      const notEquals = this.toComparable(filter.notEquals);
      return (v) => this.toComparable(getPath(v, key)) !== notEquals;
    }

    if ('anyOf' in filter && filter.anyOf !== undefined) {
      const anyOf = filter.anyOf.map((f) => this.toComparable(f));
      return (v) => {
        const value = this.toComparable(getPath(v, key));
        if (Array.isArray(value)) {
          return anyOf.some((f) =>
            value.some((v) => this.toComparable(v) === f),
          );
        }
        return anyOf.includes(value);
      };
    }

    if ('noneOf' in filter && filter.noneOf !== undefined) {
      const noneOf = filter.noneOf.map((f) => this.toComparable(f));
      return (v) => {
        const value = this.toComparable(getPath(v, key));
        if (Array.isArray(value)) {
          return noneOf.every((f) =>
            value.every((v) => this.toComparable(v) !== f),
          );
        }
        return !noneOf.includes(value);
      };
    }

    if ('match' in filter && filter.match !== undefined) {
      const match = this.toComparable(filter.match);
      return (v) => {
        const value = this.toComparable(getPath(v, key));
        if (typeof value === 'string') {
          return value.includes(match);
        }
        return false;
      };
    }

    if ('startsWith' in filter && filter.startsWith !== undefined) {
      const startsWith = this.toComparable(filter.startsWith);
      return (v) => {
        const value = this.toComparable(v[key]);
        if (typeof value === 'string') {
          return value.startsWith(startsWith);
        }
        return false;
      };
    }

    if (
      'from' in filter &&
      filter.from !== undefined &&
      filter.to !== undefined
    ) {
      const from = this.toComparable(filter.from);
      const to = this.toComparable(filter.to);
      return (v) => {
        const value = this.toComparable(getPath(v, key));
        return value >= from && value <= to;
      };
    }

    return () => false;
  }

  private toComparable(value: any) {
    if (typeof value === 'string') {
      return value.toLowerCase();
    } else {
      return value;
    }
  }

  private handleError(
    error: Error | null,
    type: RestorableType<any>,
    message: string,
    additionalData?: any,
  ): void {
    Error.stackTraceLimit = 50;
    const innerError = error ? error : new Error(message);
    const entityTypeName = new type().type;
    const errorMessage = error ? `: ${error.message}` : '';
    this.loggerService.warn(
      `${message} [${String(entityTypeName)} entity]${errorMessage}`,
      {
        stack: innerError.stack,
        additionalData,
      },
    );

    throw innerError;
  }
}
