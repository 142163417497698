// export * from './views';
// export * from './components';
import { MultiPluginFactory } from '@/features/core/plugin';
import { tourDropsApiPlugin, tourDropsApiRegistryPlugin } from './api';
import {
  dropsServicePlugin,
  pickingReceptaclesServicePlugin,
  dropsHandoverServicePlugin,
  dropStatusToActionMiddlewareServicePlugin,
  dropsSortingServicePlugin,
  pickingOrderServicePlugin,
} from './services';
import { tourDropsEntityPlugin } from './entities';

export * from './services';
export * from './types';
export * from './api';
export * from './entities';
export * from './errors';
export * from './helpers';

export const dropsPlugin = MultiPluginFactory.with({
  tourDropsApiPlugin,
  tourDropsApiRegistryPlugin,
  dropsServicePlugin,
  tourDropsEntityPlugin,
  pickingReceptaclesServicePlugin,
  dropsHandoverServicePlugin,
  dropStatusToActionMiddlewareServicePlugin,
  dropsSortingServicePlugin,
  pickingOrderServicePlugin,
});
