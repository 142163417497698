import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { TourApiClient } from '../api';
import { TourItem } from '../entities';
import { TourDropItem } from '@/features/tour-drops/entities';
import { Storage } from '@/features/core/storage';
import { Tour, TourStatus } from '../types';
import { useTours } from '../composables';
import { DropActions, DropStatus } from '@/features/tour-drops/types';
import { formatDateAtom } from '@/composables/useDateFormat';
import { toRawRecursive } from '@/utils/helpers/toRaw';
import { ProductsService } from '@/features/products';
import { Order, OrderAdapterService } from '@/features/orders';
import { configurationServicePlugin } from '@/features/configuration';
import { LoggerService } from '@/features/core/logger';

const MISSING_ORDERS_ERROR_MESSAGE = 'Missing orders at login';

export class ToursService {
  private dropStatusToActionMap = <{ [key in DropStatus]?: DropActions }>{};

  constructor(
    private toursApiClient: TourApiClient,
    private storage: Storage,
    private productsService: ProductsService,
    private orderAdapterService: OrderAdapterService<Tour>,
    private loggerService: LoggerService,
  ) {
    this.dropStatusToActionMap = <{ [key in DropStatus]?: DropActions }>{
      [DropStatus.open]: DropActions.start_drop_off,
      [DropStatus.delivery_in_progress]: DropActions.confirm_arrival,
      [DropStatus.delivery_completed]: DropActions.start_picking_receptacles,
      [DropStatus.picking_receptacles]: DropActions.all_picked_up,
      [DropStatus.picking_receptacles_completed]: DropActions.start_handover,
      [DropStatus.start_handover]: DropActions.start_handover,
      [DropStatus.handover_in_progress]: DropActions.start_handover,
    };
  }

  async fetchAllTours(): Promise<Tour[]> {
    const pickingOrderDependencyActive = await configurationServicePlugin
      .get()
      .getFeatureOption(
        'driverApp',
        'pickingOrdersDependencyActive',
        'boolean',
      );

    await this.clearAllToursData(pickingOrderDependencyActive);
    const toursList = await this.toursApiClient.getTours();

    const { tours } = useTours();

    // Store tours in indexedDB
    await Promise.all(
      toursList.map((tour) => {
        tour.drops.forEach((drop) => {
          if (this.dropStatusToActionMap[drop.status as DropStatus]) {
            drop.actions = [
              this.dropStatusToActionMap[
                drop.status as DropStatus
              ] as DropActions,
            ];
          }
        });
        return this.storage.save(TourItem.from(tour));
      }),
    );

    // Store tour drops in indexedDB
    await Promise.all(
      toursList.map((tour) =>
        Promise.all(
          tour.drops.map((drop) =>
            this.storage.save(
              TourDropItem.from({
                ...drop,
                status: drop.status as DropStatus,
                tourId: tour.id,
                id: drop.reference,
              }),
            ),
          ),
        ),
      ),
    );

    if (pickingOrderDependencyActive) {
      const productSkus: string[] = toursList
        .flatMap((tour) =>
          tour.deliveryItems.map(
            (deliveryItem) => deliveryItem.productReference,
          ),
        )
        .filter((value, index, array) => array.indexOf(value) === index);
      await this.productsService.fetchBySkus(productSkus);

      void this.storage.bulkSave(
        await this.orderAdapterService.createOrders(toursList),
      );
    }

    tours.value = toursList;
    return toursList;
  }

  async loadAllTours(): Promise<void> {
    const { tours } = useTours();
    tours.value = (await this.storage.getAll(TourItem)).sort(
      (a, b) =>
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
    );
  }

  async clearAllToursData(removeOrders = false): Promise<void> {
    await this.storage.removeAll(TourItem);
    await this.storage.removeAll(TourDropItem);
    if (removeOrders) await this.storage.removeAll(Order);
  }

  async updateTourStatus(
    tour: Tour,
    status: TourStatus,
    scheduled = true,
    waitForSync = false,
  ): Promise<void> {
    tour.statusChangedAt = formatDateAtom(new Date());
    const result = entityRepositoryPlugin.get().save(
      TourItem.from({
        ...tour,
        status,
      }),
      { waitForSync },
    );
    if (scheduled) {
      await result.scheduled;
    }
    if (waitForSync) {
      await result.completed;
    }
  }

  async revertTourStatus(tour: Tour, status: TourStatus): Promise<void> {
    await this.storage.save(
      toRawRecursive(
        TourItem.from({
          ...tour,
          status,
        }),
      ),
    );
  }

  logMissingOrderReferences(orders: Order[], tours: Tour[]): void {
    const notFoundOrderReferences: string[] = [];
    tours.forEach((tour) =>
      tour.drops.forEach((drop) =>
        drop.orderReferences.forEach((orderReference) => {
          if (
            !orders.find((order) => order.orderReference === orderReference)
          ) {
            notFoundOrderReferences.push(orderReference);
          }
        }),
      ),
    );
    if (!notFoundOrderReferences.length) {
      return;
    }
    this.loggerService.log(
      40,
      MISSING_ORDERS_ERROR_MESSAGE,
      notFoundOrderReferences,
    );
  }
}
